@use 'sass:math';
$size: 150px;

.container {
  display: flex;
  align-items: center;
  padding: math.div($size, 2);
  justify-content: center;

  overflow: hidden;
  position: relative;
}

.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  height: $size;
  width: $size;
  transform: scale(1);
  animation: pulse-green 2s infinite;
}

.green {
  background: rgba(0, 215, 125, 1);
  box-shadow: 0 0 0 0 rgba(0, 215, 125, 1);
  animation: pulse-green 2s infinite;
}

.logoBg {
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  width: $size;
  height: $size;
}

.logo {
  color: #000;
  position: absolute;
  width: $size;
  height: $size;
  
  svg {
    width: $size;
    height: $size;
  }
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 215, 125, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 math.div($size, 2) rgba(0, 215, 125, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 215, 125, 0);
  }
}