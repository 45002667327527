/* .c-card
-----------------------------------------------------------*/
$r: ".c-card";

// block
//-----------------------------------------------------------
#{$r} {
  display: grid;
  align-items: center;
  grid-gap: $size * 2;
  width: 100%;
  margin: 0 auto;
  padding: $size * 2;
  max-width: $size * 44;
  min-height: $size * 22;
  border-radius: $border-radius;
  // background-color: $gray-dark;
  //background-image: linear-gradient(0deg, #282828 0%, #838383 50%, #313131 100%);
  background-image: linear-gradient(180deg, #36373B 0%, #2B2A2F 50%, #252429 75%, #232325 76%, #1D1D1F 100%);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

//#36373B 0% #2B2A2F 50% #252429 75% #232325 76% #1D1D1F 100%);

  // element
  //---------------------------------------------------------
  &__name {
    color: $white;
    height: $size * 5;
    padding: 0 $size * 2;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: $size * 5;
    border-radius: $border-radius;
    background-color: rgba(64, 77, 97, 0.25);
  }

  &__number {
    color: $white;
    font-size: 24px;
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
