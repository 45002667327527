.container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  height: 100%;
  background-color: #000;

  svg {
    width: 80%;
    height: auto;
  }
}